import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  border-color: #263946;
`

export const TableContainer = styled.div`
  display: flex;
  //width: 100%;
  width: 50%;
  flex-direction: column;
  color: #bfc1c8;
`