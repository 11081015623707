import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: Kraken Plex Mono, monospace;
  }

  html, body {
    background-color: #121723;
    height: 100%;
  }

  #root {
    margin: 0 auto;
    height: 100%;
  }
`