import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 79vh;
  
  svg {
    width: 4em;
    height: 4em;
  }
`
